<div class="btn-group">
  <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    Actions
  </button>
  <ul class="dropdown-menu">
    <li *ngFor="let linkUrlButton of linkUrlButtons">
      <a hasAnyRole [viewRoles]="linkUrlButton.viewRoles" class="dropdown-item" [routerLink]="linkUrlButton.linkUrl">{{ linkUrlButton.labelButton }}</a>
    </li>
  </ul>
</div>

