import {Injectable} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {jwtDecode} from "jwt-decode";
import {LoggedUser} from "../interfaces/logged-user";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private keycloakService: KeycloakService) { }

  async getToken(): Promise<string> {
    return await this.keycloakService.getToken();
  }

  getTokenNoAsync(): Promise<string> {
    return this.keycloakService.getToken();
  }

  async getDecodedToken(): Promise<any> {
    const token = await this.getToken();
    return jwtDecode(token);
  }

  async getUserRoles(): Promise<string[]> {
    const decodedToken = await this.getDecodedToken();
    return decodedToken.realm_access.roles;
  }

  async getUserName(): Promise<string> {
    const decodedToken = await this.getDecodedToken();
    return decodedToken.preferred_username;
  }
  async logout(): Promise<void> {
    await this.keycloakService.logout();
  }

  getLoggedUserLocalStorage(): LoggedUser | null{
    if (!localStorage.getItem('loggedUser')) {
      return null;
    }
    return JSON.parse(localStorage.getItem('loggedUser')!);
  }

  setLoggedUserOnLocalStorage(token: string){
    localStorage.setItem('loggedUser', JSON.stringify(jwtDecode(token)));
  }

  getRoleFromLocalStorage(){
    const loggedUser = this.getLoggedUserLocalStorage();
    return loggedUser?.resource_access.srvregbe_public.roles[0];
  }
}
