import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, Subject, Subscription} from "rxjs";
import {Roles} from "../../helper/role";

@Component({
  selector: 'app-common-actions-bar',
  templateUrl: './common-actions-bar.component.html',
  styleUrl: './common-actions-bar.component.css'
})
export class CommonActionsBarComponent implements OnInit, OnDestroy{

  @Input()
  buttonLabel: string = '';
  @Input()
  linkUrl: string = '';
  @Output()
  searchBox: EventEmitter<string> = new EventEmitter();
  @Output()
  debounceSearchBox:EventEmitter<string> = new EventEmitter();
  debounce: Subject<string> = new Subject<string>();
  private debounceSubscription?: Subscription;


  ngOnDestroy(): void {
    this.debounceSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.debounceSubscription = this.debounce.pipe(debounceTime(500))
      .subscribe(value => this.debounceSearchBox.emit(value))
  }

  onBoxValue(value: string){
    this.searchBox.emit(value);
  }

  onDebounce(value: string){
    this.debounce.next(value);
  }

  protected readonly Roles = Roles;
}
