import {Component, Input, OnInit} from '@angular/core';
import {MediaService} from "../../../service-media/services/media.service";
import {getFileName} from "../../../service-media/util/MediaUtil";

@Component({
  selector: 'app-preview-images',
  templateUrl: './preview-images.component.html',
  styleUrl: './preview-images.component.css'
})
export class PreviewImagesComponent implements OnInit{

  imageUrl: string | null = null;
  @Input()
  fileUrl!: string;

  constructor(private mediaService: MediaService) { }

  ngOnInit(): void {
    this.mediaService.previewImage(this.fileUrl).subscribe({
        next: res => {
          this.imageUrl = `data:image/png;base64,${res.image}`;
        },
        error: () => {
          this.imageUrl = 'nothing found';
        }
      }
    );
  }

  protected readonly getFileName = getFileName;
}
