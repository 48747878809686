import {Component, Input} from '@angular/core';
import {LoggedUser} from "../../../auth/interfaces/logged-user";
import {AuthService} from "../../../auth/services/auth.service";
import {MenuItem} from "../../interfaces/menu-item";
import {Roles} from "../../helper/role";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {

  constructor(private authService: AuthService) {}

  @Input()
  loggedUser?: LoggedUser;

  headerMenu: MenuItem[] = [
    {
      title: 'Services',
      router: 'services/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Categories',
      router: 'categories/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Tags',
      router: 'tags/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Variables',
      router: 'variables/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Spatial Extent',
      router: 'spatialExtents/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Formats',
      router: 'formats/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Crs',
      router: 'crss/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Projection',
      router: 'projections/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Sensor',
      router: 'sensors/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Data Type',
      router: 'dataTypes/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    },
    {
      title: 'Data Tag',
      router: 'dataTags/list',
      viewRoles: [Roles.ADMIN, Roles.USER]
    }
  ]

  async logout(){
    localStorage.removeItem('loggedUser');
    await this.authService.logout();
  }
}
