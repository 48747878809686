import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loader-spinner',
  templateUrl: './loader-spinner.component.html',
  styleUrl: './loader-spinner.component.css'
})
export class LoaderSpinnerComponent {
  @Input()
  labelName: string = 'Searching';
}
