import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-action-buttons-save-forms',
  templateUrl: './action-buttons-save-forms.component.html',
  styles: ``
})
export class ActionButtonsSaveFormsComponent {

  @Input()
  cancelRedirectUrl: string = '';
  @Input()
  isLoading: boolean = false;

}
