import {Directive, ElementRef, input, Input, OnInit} from '@angular/core';
import {AuthService} from "../../auth/services/auth.service";

@Directive({
  selector: '[hasAnyRole]'
})
export class HasAnyRoleDirective {

  constructor(private authService: AuthService,
              private elementRef: ElementRef<HTMLElement>) {
  }

  @Input()
  set viewRoles(roles: string[] | undefined){
    let loggedRole = this.authService.getRoleFromLocalStorage();
    if (!loggedRole){
      this.elementRef.nativeElement.hidden = true;
    } else if (!roles || roles.length === 0){
      this.elementRef.nativeElement.hidden = false;
    }
    else {
      this.elementRef.nativeElement.hidden= !roles.includes(loggedRole);
    }
  }
}
