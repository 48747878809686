<nav class="navbar sticky-top navbar-expand-lg bg-body-tertiary border-bottom border-body bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand mb-0 h1" routerLink="services/list">DESP Backoffice</a>
    <div class="navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item" *ngFor="let item of headerMenu">
          <a hasAnyRole [viewRoles]="item.viewRoles" class="nav-link active" aria-current="page" [routerLink]='item.router' routerLinkActive="active">{{item.title}}</a>
        </li>
      </ul>
      <div class="navbar-nav ms-auto">
        <div class="nav-item d-flex flex-column align-items-center">
          <span class="nav-item navbar-text me-3">
            {{ loggedUser?.preferred_username }}({{ loggedUser!.resource_access.srvregbe_public.roles[0] }})
          </span>
          <button class="nav-item btn nav-link" (click)="logout()">Logout</button>
        </div>
      </div>
    </div>
  </div>
</nav>
