import {Component, Input, OnInit} from '@angular/core';
import {LinkUrlButton} from "../../interfaces/link-url-button";

@Component({
  selector: 'app-actions-buttons',
  templateUrl: './actions-buttons.component.html',
  styles: ``
})
export class ActionsButtonsComponent {

  @Input()
  linkUrlButtons: LinkUrlButton[] = [];

}
