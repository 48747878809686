import {Component, Input} from '@angular/core';
import {ErrorResponse} from "./interfaces/error-response";

@Component({
  selector: 'app-messages-toaster',
  templateUrl: './messages-toaster.component.html',
  styles: ``
})
export class MessagesToasterComponent {

  @Input()
  errorResponse: ErrorResponse = {
    code: "",
    message: ""
  };

  @Input()
  showToast: boolean = false;
}
