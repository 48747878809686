import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-field-validation',
  templateUrl: './field-validation.component.html',
  styles: ``
})
export class FieldValidationComponent {

  @Input()
  myForm!: FormGroup;
  @Input()
  field: string = '';

  isValidField(): boolean | null {
    return this.myForm.controls[this.field].errors
      && this.myForm.controls[this.field].touched;
  }

  getFieldError(): string | null {

    if (!this.myForm.controls[this.field]) return null;

    const errors = this.myForm.controls[this.field].errors || {};

    for (const key of Object.keys(errors)) {
      switch (key) {
        case 'required':
          return 'This Field is required.';
        /*       case 'minlength':
                 return `Mínimo ${ errors['minlength'].requiredLength } caracters.`;
             }*/
        case 'maxElementsExceeded':
          return 'Elements exceeded'
      }
    }
      return null;
  }
}
