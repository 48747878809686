import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {LoaderSpinnerComponent} from './components/loader-spinner/loader-spinner.component';
import {CommonActionsBarComponent} from './components/common-actions-bar/common-actions-bar.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {ActionsButtonsComponent} from './components/actions-buttons/actions-buttons.component';
import {MessagesToasterComponent} from './components/messages-toaster/messages-toaster.component';
import {
  ActionButtonsSaveFormsComponent
} from './components/action-buttons-save-forms/action-buttons-save-forms.component';
import {PreviewImagesComponent} from './components/preview-images/preview-images.component';
import {FieldValidationComponent} from './components/field-validation/field-validation.component';
import { HasAnyRoleDirective } from './directives/has-any-role.directive';


@NgModule({
  declarations: [
    HeaderComponent,
    LoaderSpinnerComponent,
    CommonActionsBarComponent,
    PaginationComponent,
    ActionsButtonsComponent,
    MessagesToasterComponent,
    ActionButtonsSaveFormsComponent,
    PreviewImagesComponent,
    FieldValidationComponent,
    HasAnyRoleDirective,
  ],
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive
  ],
    exports: [
        HeaderComponent,
        LoaderSpinnerComponent,
        CommonActionsBarComponent,
        PaginationComponent,
        ActionsButtonsComponent,
        MessagesToasterComponent,
        ActionButtonsSaveFormsComponent,
        PreviewImagesComponent,
        FieldValidationComponent,
        HasAnyRoleDirective,
    ]
})
export class SharedModule { }
